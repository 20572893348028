<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="text-xs-center pt-0">
        <div class="mb-2">
          <div class="mb-3 headline font-weight-medium">
            {{ attribute.name }}
          </div>
          <div class="mb-3">
            <v-radio-group v-model="allowLink" row class="ma-0" hide-details>
              <v-layout row wrap>
                <v-flex xs12 class="pb-2">
                  <v-radio :value="0">
                    <template slot="label">
                      <tooltip name="ShortcodeUserAnswer">
                        <div class="black--text">
                          {{
                            $t(
                              "question-shortcode.insert-current-answer-from-guest",
                            )
                          }}
                        </div>
                      </tooltip>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs12>
                  <v-radio :value="1">
                    <template slot="label">
                      <div class="black--text">
                        {{
                          $t(
                            "question-shortcode.insert-the-question-answer-directly-from-link",
                          )
                        }}
                      </div>
                    </template>
                  </v-radio>
                </v-flex>
              </v-layout>
            </v-radio-group>
          </div>
          <template v-if="allowLink === 1">
            <div class="mb-3">
              <v-text-field
                v-model="text"
                :label="$t('shortcode.question-link-text')"
              ></v-text-field>
            </div>
            <div class="mb-3">
              <v-layout class="row wrap">
                <v-flex class="xs12 mb-4 pr-2">
                  <v-select
                    v-if="attribute.type === 'options'"
                    v-model="attributeValues[attribute.slug]"
                    :items="attribute.options"
                    item-text="name"
                    item-value="id"
                    :multiple="attribute.is_collection"
                    return-object
                    :label="$tc('quill.questions_shortcode.answer', 1)"
                    v-validate.disable="'required'"
                    :error-messages="errors.collect('answer')"
                    :data-vv-name="'answer'"
                    :data-vv-as="$tc('quill.questions_shortcode.answer', 2)"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template slot="item" slot-scope="data">
                      <span class="slot-item">{{ data.item.name }}</span>
                    </template>
                  </v-select>
                  <v-textarea
                    v-if="attribute.type === 'text'"
                    v-model="attributeValues[attribute.slug]"
                    :label="$tc('quill.questions_shortcode.answer', 1)"
                    no-resize
                    v-validate.disable="'required'"
                    :error-messages="errors.collect('answer')"
                    :data-vv-name="'answer'"
                    :data-vv-as="$tc('quill.questions_shortcode.answer', 2)"
                  ></v-textarea>
                  <v-text-field
                    v-if="
                      attribute.type === 'varchar' ||
                      attribute.type === 'integer'
                    "
                    v-model="attributeValues[attribute.slug]"
                    :label="$tc('quill.questions_shortcode.answer', 1)"
                    v-validate.disable="'required'"
                    :error-messages="errors.collect('answer')"
                    :data-vv-name="'answer'"
                    :data-vv-as="$tc('quill.questions_shortcode.answer', 2)"
                  ></v-text-field>
                  <CustomDatePicker
                    v-if="attribute.type === 'datetime'"
                    v-model="attributeValues[attribute.slug]"
                  ></CustomDatePicker>
                </v-flex>
              </v-layout>
            </div>
          </template>
        </div>
        <v-flex>
          <v-btn
            round
            class="theme-primary-bg theme-on-primary text-none"
            @click="cancel"
            >{{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            round
            class="theme-primary-bg theme-on-primary text-none"
            @click="confirm()"
            >{{ $t("insert") }}
          </v-btn>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import qs from "qs";
import CustomDatePicker from "@/components/CustomFields/CustomDatePicker.vue";
import moment from "moment-timezone";

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    attributeValues: {},
    attribute: {},
    text: null,
    allowLink: 0,
  }),
  components: {
    CustomDatePicker,
  },
  methods: {
    async open(meta) {
      this.text = meta.name;
      this.dialog = true;
      this.attribute = meta;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    resetData() {
      this.dialog = false;
      this.resolve = null;
      this.attributeValues = {};
      this.attribute = {};
      this.text = null;
      this.allowLink = 0;
    },
    cancel() {
      this.resolve(null);
      this.resetData();
    },
    async confirm(val = {}) {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.dialog = false;

      if (this.allowLink) {
        const query = Object.keys(this.attributeValues).reduce((q, key) => {
          if (this.attribute.type === "options") {
            if (this.attribute.is_collection) {
              q[`signup_${key}`] = this.attributeValues[key].map((el) => el.id);
            } else {
              q[`signup_${key}`] = this.attributeValues[key]["id"];
            }
          } else if (this.attribute.type === "datetime") {
            q[`signup_${key}`] = moment
              .tz(this.attributeValues[key], this.userTimezone)
              .format("YYYY-MM-DD 00:00:00");
          } else {
            q[`signup_${key}`] = this.attributeValues[key];
          }

          return q;
        }, {});

        val = {
          query: qs.stringify(query, { encode: false }),
        };
      }

      val.text = this.text;

      this.resolve(val);
      this.resetData();
    },
  },
};
</script>
