<template>
  <v-container class="general group-page">
    <page-title :component="'GroupAttributesPage'" class="mb-5">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeTab === 0"
          ref="languageSwitcher"
          :events="true"
          @click="updateGroup('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <v-card class="mb-5">
      <v-layout row>
        <v-btn
          v-for="(tab, i) in tabs"
          :key="`section-${i}`"
          block
          flat
          :class="[
            'ma-0 x-large text-none border-radius tab-btn',
            { active: activeTab === i },
          ]"
          @click="activeTab = i"
        >
          <div class="sw-h4 tab-btn-text">{{ tab.title }}</div>
        </v-btn>
      </v-layout>
    </v-card>
    <v-window v-model="activeTab" class="mb-5 pb-5">
      <v-window-item v-for="(tab, i) in tabs" :key="`content-${i}`" :value="i">
        <component :is="tab.component" />
      </v-window-item>
    </v-window>
    <v-btn
      round
      large
      class="ml-0 mr-3 white sw-accent text-none"
      :loading="isLoading"
      @click="fetchAll"
    >
      {{ $t("common.reset") }}
    </v-btn>
    <v-btn
      round
      large
      class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
      :loading="isLoading"
      @click="updateGroup()"
    >
      {{ $t("common.save") }}
    </v-btn>
    <template v-if="group && group.subdomain && tabs[activeTab] && tabs[activeTab].id === 1">
      <div
        class="preview-container"
        :class="{ closed: !previewState, expanded: previewExpanded }"
      >
        <div class="expand-button" @click="previewExpanded = !previewExpanded">
          <font-awesome-icon
            :icon="previewExpanded ? 'compress-alt' : 'expand-alt'"
            class="icon"
          />
        </div>
        <iframe :src="landingPageLink" frameborder="0"></iframe>
      </div>
      <AppSpeedDial
        v-model="previewState"
        :icon="'eye'"
        :active-icon="'times'"
        :hint="speedDialHint"
      />
    </template>
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import LandingPageSettings from "@/components/GroupPage/LandingPageSettings.vue";
import AppSettings from "@/components/GroupPage/AppSettings.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    generalAttributes: [],
    activeTab: 0,
    previewState: false,
    previewExpanded: false,
  }),
  computed: {
    defaultDomain() {
      return process.env.VUE_APP_DEFAULT_DOMAIN;
    },
    landingPageLink() {
      return `https://${this.group.subdomain}.${this.defaultDomain}`;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    group: {
      get() {
        return this.$store.getters.groupPageGroup;
      },
      set(val) {
        this.$store.dispatch("setGroupPageGroup", val);
      },
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    groupAttributes: {
      get() {
        return this.$store.getters.groupPageGroupAttributes;
      },
      set(val) {
        this.$store.dispatch("setGroupPageGroupAttributes", val);
      },
    },
    speedDialHint() {
      return this.previewState
        ? this.$t("group_page.speed_dial_hide_hint")
        : this.$t("group_page.speed_dial_see_hint");
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isLandingPageEnabled() {
      return process.env.VUE_APP_LANDINGPAGE_ENABLED === "true";
    },
    tabs() {
      return [
        {
          id: 1,
          title: this.$t("group_page.landing_page"),
          component: LandingPageSettings,
          enabled: this.isLandingPageEnabled,
        },
        {
          id: 2,
          title: this.$t("group_page.app"),
          component: AppSettings,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
  },
  components: {
    LanguageSwitcher,
  },
  beforeCreate() {
    this.group = {
      attributes: [],
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      const tabId = parseInt(this.$route.query.tab, 10);

      this.activeTab = this.tabs.findIndex((el) => el.id === tabId);

      this.$router.replace({ query: null }, () => {});
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler() {
        this.fetchAll();
      },
    },
    activeTab: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getGroup();
        await this.listGroupGeneralAttributes();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.errorMessageShow(error);
      }
    },
    async getGroup() {
      if (!this.groupId) return;

      const params = [
        this.groupId,
        {
          with_attributes: 1,
          expand_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groups.get(...params);

      this.group = response.data.data;
      this.groupAttributes = response.data.data.attributes;
    },
    async listGroupGeneralAttributes() {
      const params = { per_page: 500 };

      const response = await this.$api.groupGeneralAttributes.list(params);

      this.generalAttributes = response.data.data;
    },
    transformAttributes(attributes) {
      if (!attributes) return {};

      const result = {};

      for (const [key, value] of Object.entries(attributes)) {
        const attribute = this.generalAttributes.find((el) => el.slug === key);

        if (!attribute) continue;

        const type = attribute.type;

        if (
          type === "varchar" ||
          type === "text" ||
          type === "translatable_text" ||
          type === "integer" ||
          type === "float"
        ) {
          result[key] = value;
        }

        if (type === "image") {
          result[key] = value ? value.id : null;
        }

        if (type === "boolean") {
          result[key] = value ? 1 : 0;
        }
      }

      return result;
    },
    async updateGroup(action) {
      this.errors.clear();

      const isLandingPageValid = await this.$validator.validateAll(
        "landing-page",
      );

      if (!isLandingPageValid) {
        this.activeTab = 0;
        return;
      }

      try {
        this.isLoading = true;

        const specs = [
          this.group.id,
          {
            subdomain: this.group.subdomain,
            public: this.group.public,
            protected: this.group.protected,
            contact_form_recipient_email:
              this.group.contact_form_recipient_email,
            ...this.transformAttributes(this.groupAttributes),
            lang: this.appContentLanguage,
          },
        ];

        await this.$api.groups.update(...specs);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("group-updated"),
        });
      } catch (error) {
        this.isLoading = false;

        if (error.response && error.response.data.error.message) {
          switch (error.response.data.error.message) {
            case "The subdomain may only contain letters, numbers, dashes and underscores.":
              this.errors.add({
                msg: this.$t("group_page.invalid_subdomain_error"),
                scope: "landing-page",
                field: "subdomain",
              });
              this.activeTab = 2;
              break;
            case "The subdomain has already been taken.":
              this.errors.add({
                msg: this.$t("group_page.subdomain_taken_error"),
                scope: "landing-page",
                field: "subdomain",
              });
              this.activeTab = 2;
              break;
            default:
              this.errorMessageShow(error);
          }
        }
        return;
      }

      if (action === "stay") {
        this.$refs.languageSwitcher.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-page {
  .tab-btn {
    .tab-btn-text {
      color: $on-primary;
    }

    &.active {
      background: $accent;

      .tab-btn-text {
        color: $on-accent;
      }
    }
  }

  .expand-button {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background: $accent;
    border: 1px solid $accent;
    border-radius: 50%;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    .icon {
      font-size: 12px;
      color: $on-accent;
      transform: rotate(90deg);
    }
  }

  .preview-container {
    position: fixed;
    width: 40vw;
    height: 60vh;
    z-index: 5;
    border: 1px solid $accent;
    right: 84px;
    bottom: 106px;
    transition: all 0.3s ease;
    clip-path: circle(160% at 100% 100%);
    background-color: #ffffff;

    iframe {
      width: 100%;
      height: 100%;
    }

    &.closed {
      visibility: none;
      clip-path: circle(0% at 100% 100%);
    }

    &.expanded {
      width: 100vw;
      height: 100vh;
      bottom: 0;
      right: 0;

      .expand-button {
        top: 20px;
        left: 30px;
        background: #ffffff;

        .icon {
          color: $accent;
        }
      }
    }
  }
}
</style>
